// "DUCKS" Pattern - the new recommended pattern for modern redux
import { createSlice } from "@reduxjs/toolkit"
import { isBrowser } from "@/libs/helpers"

export type Theme = "light" | "dark"

function getInitialState() {
  let theme: Theme = "dark"
  if (isBrowser() && window.matchMedia) {
    const systemThemePref = window.matchMedia("(prefers-color-scheme: dark)")
      .matches
      ? "dark"
      : "light"
    theme = systemThemePref
  }
  return theme
}

const themeSlice = createSlice({
  name: "activeTheme",
  initialState: getInitialState,
  reducers: {
    setTheme(state, action: { payload: Theme }) {
      return action.payload
    },
  },
})

// createSlice creates actions for us from our reducer defs
export const { setTheme } = themeSlice.actions
export default themeSlice.reducer
