import { createContext, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"

export type SeoContextValue =
  | Queries.GeneralSeoDataQuery["allSanityGeneral"]["nodes"][0]["generalSettings"]["generalSEO"]
  | null

const SeoContext = createContext<SeoContextValue>(null)

export const SeoProvider = ({ children }: { children: React.ReactNode }) => {
  const seoQuery: Queries.GeneralSeoDataQuery = useStaticQuery(graphql`
    query GeneralSeoData {
      allSanityGeneral {
        nodes {
          generalSettings {
            generalSEO {
              ...SEOObjectData
            }
          }
        }
      }
    }
  `)

  const data = seoQuery.allSanityGeneral.nodes[0].generalSettings.generalSEO

  return <SeoContext.Provider value={data}>{children}</SeoContext.Provider>
}

export const useGeneralSeo = () => {
  const generalSeoData = useContext(SeoContext)

  return generalSeoData
}

export default SeoContext
