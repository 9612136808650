import { useState } from "react"
import { AnimatePresence } from "framer-motion"
import useKonami from "use-konami"
import { EASTER_EGG_SECRET } from "@/libs/constants"
import useTheme from "@/libs/custom-hooks/useTheme"
import ThemeController from "@/components/ThemeController"
import "@/styles/global/index.scss"

const PageWrapper = ({
  children,
  location,
}: {
  children: React.ReactNode
  location: Window["location"]
}) => {
  const { changeTheme, activeTheme } = useTheme()
  const [themeControllerVisible, setThemeControllerVisible] = useState(false)

  const allowThemeControl = !["/media-kit/"].includes(location.pathname)

  useKonami({
    sequence: EASTER_EGG_SECRET.split(""),
    onUnlock: () => {
      setThemeControllerVisible(prevState => (!prevState ? true : prevState))
    },
  })

  return (
    <>
      {children}
      <AnimatePresence>
        {themeControllerVisible && allowThemeControl && (
          <ThemeController
            initial={{
              y: 10,
              opacity: 0,
              rotateX: "-10deg",
              transformOrigin: "center -20px",
              transformPerspective: 1000,
            }}
            animate={{ y: 0, opacity: 1, rotateX: "0deg" }}
            exit={{ y: 10, opacity: 0, rotateX: "-10deg" }}
            transition={{ ease: [0.2, 0, 0, 1], duration: 0.8 }}
            setThemeControllerVisible={setThemeControllerVisible}
            changeTheme={changeTheme}
            activeTheme={activeTheme}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default PageWrapper
