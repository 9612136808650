import { type SetStateAction, forwardRef } from "react"
import { motion } from "framer-motion"
import { RiCloseFill } from "react-icons/ri"
import type { Theme } from "@/redux/features/themeSlice"
import { capitalize } from "@/libs/helpers"
import * as styles from "@/styles/modules/ThemeController.module.scss"

interface ThemeControllerProps {
  setThemeControllerVisible: React.Dispatch<SetStateAction<boolean>>
  changeTheme: (theme: Theme) => void
  activeTheme: Theme
}

const ThemeController = forwardRef<HTMLDivElement, ThemeControllerProps>(
  ({ setThemeControllerVisible, changeTheme, activeTheme }, ref) => {
    const handleThemeToggleClick: React.MouseEventHandler<
      HTMLButtonElement
    > = e => {
      e.preventDefault()
      const nextTheme = activeTheme === "light" ? "dark" : "light"
      changeTheme(nextTheme)
    }

    return (
      <div className={styles.wrapper} ref={ref}>
        <button
          className={`${styles.toggleBtn} ${activeTheme}`}
          onClick={handleThemeToggleClick}
        >
          <span className={styles.darkIcon}>🌚</span>
          <span className={styles.lightIcon}>🌞</span>
        </button>
        <p className="small">
          <strong>{`${capitalize(activeTheme)} theme`}</strong>
        </p>
        <button
          className={styles.closeBtn}
          onClick={() => setThemeControllerVisible(false)}
        >
          <RiCloseFill />
        </button>
      </div>
    )
  },
)

ThemeController.displayName = "ThemeController"

export default motion(ThemeController, { forwardMotionProps: true })
