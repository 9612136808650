import type { RootState } from "@/redux/store"
import { useEffect } from "react"
import { setTheme as setThemeAction } from "@/redux/features/themeSlice"
import { useAppDispatch, useAppSelector } from "@/redux/hooks"

/**
 * THEME LOGIC:
 * On first visiting the site, we will set the theme mode based on
 * the user's system color scheme preferences. Once set, this value
 * is stored in local storage and persisted until changed manually by
 * the user via the theme switcher in the header.
 */
export default function useTheme() {
  const dispatch = useAppDispatch()
  const activeTheme = useAppSelector(state => state.theme)

  useEffect(() => {
    setTheme(activeTheme)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * A function to change the root css vars associated with theme
   * and store the preference in local storage for persistence.
   */
  function setTheme(theme: RootState["theme"]) {
    dispatch(setThemeAction(theme))
    // Set a body class (for edge cases where background and color vars wno't do the trick)
    document.body.classList.remove("theme--dark", "theme--light")
    document.body.classList.add(`theme--${theme}`)

    // window.localStorage.setItem('theme', theme);
    const root = document.documentElement

    // Base
    const background = theme === "light" ? "#fff" : "#1a1418"
    const backgroundRGB = theme === "light" ? "255, 255, 255" : "26, 20, 24"
    const altBackground = theme === "light" ? "#F8F4ED" : "#2E2146" // coffee : midnight
    const color = theme === "light" ? "#1a1418" : "#fff"
    const colorRGB = theme === "light" ? "26, 20, 24" : "255, 255, 255"
    const dough = theme === "light" ? "#6c3c31" : "#EBBC60"
    // 404 Page
    const _404Lettering = theme === "light" ? "#C9B897" : "#4E3D6F"
    // Card Varieties
    const transparentBackgroundCardRGB =
      theme === "light" ? "125, 85, 255" : "255, 255, 255"
    const cardBackground =
      theme === "light"
        ? "#fff"
        : "linear-gradient(200deg, rgba(248, 79, 170, .06) 0%, rgba(248, 79, 170, 0) 100%), rgba(255, 255, 255, 0.03)"
    const cardHoverBackground =
      theme === "light"
        ? "#fff"
        : "linear-gradient(200deg, rgba(248, 79, 170, .06) 0%, rgba(248, 79, 170, 0) 100%), rgba(255, 255, 255, 0.06)"
    const cardBoxShadow =
      theme === "light" ? "0 12px 16px -12px rgba(21, 0, 139, 0.3)" : "none"
    const cardHoverBoxShadow =
      theme === "light" ? "0 12px 6px -12px rgba(21, 0, 139, 0.3)" : "none"
    const cardHoverTransform = theme === "light" ? "translateY(4px)" : "none"

    // Set Base
    root.style.setProperty("--background", background)
    root.style.setProperty("--background-rgb", backgroundRGB)
    root.style.setProperty("--alt-background", altBackground)
    root.style.setProperty("--color", color)
    root.style.setProperty("--color-rgb", colorRGB)
    root.style.setProperty("--dough", dough)
    // Set 404 Page
    root.style.setProperty("--404-lettering", _404Lettering)
    // Set Card Varieties
    root.style.setProperty(
      "--transparent-background-card-rgb",
      transparentBackgroundCardRGB,
    )
    root.style.setProperty("--card-background", cardBackground)
    root.style.setProperty("--card-hover-background", cardHoverBackground)
    root.style.setProperty("--card-hover-box-shadow", cardHoverBoxShadow)
    root.style.setProperty("--card-box-shadow", cardBoxShadow)
    root.style.setProperty("--card-hover-transform", cardHoverTransform)
  }

  // const currentTheme = window.localStorage.getItem('theme');

  /**
   * Handler we will pass into components to enable theme changing
   */
  function changeTheme(theme: RootState["theme"]) {
    if (theme !== activeTheme) {
      setTheme(theme)
    }
  }

  return { changeTheme, activeTheme }
}
