exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-ambassadors-index-js": () => import("./../../../src/pages/ambassadors/index.js" /* webpackChunkName: "component---src-pages-ambassadors-index-js" */),
  "component---src-pages-ambassadors-login-js": () => import("./../../../src/pages/ambassadors/login.js" /* webpackChunkName: "component---src-pages-ambassadors-login-js" */),
  "component---src-pages-mediakit-tsx": () => import("./../../../src/pages/mediakit.tsx" /* webpackChunkName: "component---src-pages-mediakit-tsx" */),
  "component---src-pages-news-personality-quiz-index-js": () => import("./../../../src/pages/news-personality-quiz/index.js" /* webpackChunkName: "component---src-pages-news-personality-quiz-index-js" */),
  "component---src-pages-news-personality-quiz-results-index-js": () => import("./../../../src/pages/news-personality-quiz/results/index.js" /* webpackChunkName: "component---src-pages-news-personality-quiz-results-index-js" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-sponsor-discounts-tsx": () => import("./../../../src/pages/sponsor-discounts.tsx" /* webpackChunkName: "component---src-pages-sponsor-discounts-tsx" */),
  "component---src-pages-subscription-preferences-js": () => import("./../../../src/pages/subscription-preferences.js" /* webpackChunkName: "component---src-pages-subscription-preferences-js" */),
  "component---src-pages-the-donut-acquires-press-sports-tsx": () => import("./../../../src/pages/the-donut-acquires-press-sports.tsx" /* webpackChunkName: "component---src-pages-the-donut-acquires-press-sports-tsx" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-pages-welcome-tsx": () => import("./../../../src/pages/welcome.tsx" /* webpackChunkName: "component---src-pages-welcome-tsx" */),
  "component---src-templates-author-page-tsx": () => import("./../../../src/templates/AuthorPage.tsx" /* webpackChunkName: "component---src-templates-author-page-tsx" */),
  "component---src-templates-block-page-tsx": () => import("./../../../src/templates/BlockPage.tsx" /* webpackChunkName: "component---src-templates-block-page-tsx" */),
  "component---src-templates-category-group-page-js": () => import("./../../../src/templates/CategoryGroupPage.js" /* webpackChunkName: "component---src-templates-category-group-page-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/CategoryPage.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/HomePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-newsletter-page-js": () => import("./../../../src/templates/NewsletterPage.js" /* webpackChunkName: "component---src-templates-newsletter-page-js" */),
  "component---src-templates-product-landing-page-tsx": () => import("./../../../src/templates/ProductLandingPage.tsx" /* webpackChunkName: "component---src-templates-product-landing-page-tsx" */)
}

