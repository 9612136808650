import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

interface ErrorModalState {
  isOpen: boolean
  errors: string[]
}

const initialState: ErrorModalState = {
  isOpen: false,
  errors: [],
}

const errorModalSlice = createSlice({
  name: "errorModal",
  initialState,
  reducers: {
    openErrorModal: (state, action: PayloadAction<string[]>) => {
      state.isOpen = true
      state.errors = action.payload
    },
    closeErrorModal: state => {
      state.isOpen = false
      state.errors = []
    },
  },
})

export const { openErrorModal, closeErrorModal } = errorModalSlice.actions
export default errorModalSlice.reducer
