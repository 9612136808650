import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { Provider } from "react-redux"
// import { PersistGate } from "@/redux-persist/integration/react"
import { store } from "@/redux/store"
// import { store, persistor } from "@/redux/store"
import { SeoProvider } from "@/context/SeoContext"

const RootWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Provider store={store}>
      <SeoProvider>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY as string}
        >
          {/* <PersistGate loading={null} persistor={persistor}> */}
          {children}
          {/* </PersistGate> */}
        </GoogleReCaptchaProvider>
      </SeoProvider>
    </Provider>
  )
}

export default RootWrapper
