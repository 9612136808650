import { createSlice } from "@reduxjs/toolkit"

interface Product {
  type: string
  title: string
  subscribeId: string
  productId: string
}

interface ProductsState {
  products: Product[]
}

const initialState: ProductsState = {
  products: [],
}

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts(state, action) {
      state.products = action.payload
    },
    clearProducts(state) {
      state.products = []
    },
  },
})

export const { setProducts, clearProducts } = productsSlice.actions
export default productsSlice.reducer
